<template>
  <v-card class="mt-5" elevation="0">
    <v-container>
      <v-row align="center" justify="space-between">
        <v-col :cols="isMobile ? 12 : 8">
          <div>
            <div class="text-h5 font-weight-bold">
              {{ $t("__transaction_analysis_chart") + " - " }}
              {{ mainTimeFormat }}
              <span
                class="text-subtitle-1 font-weight-black blue-grey--text darken-3"
                v-if="compareTimeFormat"
              >
                {{ $t("__analytics_times_compare_title") }}
                {{ compareTimeFormat }}</span
              >
            </div>
          </div>
        </v-col>
        <v-col :cols="isMobile ? 12 : 4" class="text-right">
          <v-btn @click="toSetChart" color="primary">{{
            $t("__analytics_times")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <!-- total trasaction amount -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_revenue") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalTransactionAmount | currency }}
                </h3>
                <template v-if="compareQtyOfOrder.length">
                  <template v-if="totalTransactionAmount > totalCompareAmount">
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span
                      class="success--text"
                      v-if="totalTransactionAmount > totalCompareAmount"
                    >
                      {{
                        Math.abs(totalTransactionAmount - totalCompareAmount)
                          | currency
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="totalTransactionAmount < totalCompareAmount"
                  >
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >
                    <span class="error--text">
                      {{
                        Math.abs(totalTransactionAmount - totalCompareAmount)
                          | currency
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >
                    <span class="success--text">
                      {{
                        (totalTransactionAmount - totalCompareAmount) | currency
                      }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="revenueChart"
              :chartData="totalRevenueData"
              :options="totalRevenueOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
        <!-- total orders qty -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_paid_orders") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalQuantityOfOrder }}
                </h3>
                <template v-if="compareQtyOfOrder.length">
                  <template v-if="totalQuantityOfOrder > totalCompareQty">
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span class="success--text">
                      {{
                        Math.abs(totalQuantityOfOrder - totalCompareQty)
                      }}</span
                    >
                  </template>
                  <template v-else-if="totalQuantityOfOrder < totalCompareQty">
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >

                    <span class="error--text">
                      {{ Math.abs(totalQuantityOfOrder - totalCompareQty) }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >

                    <span class="success--text">
                      {{ totalQuantityOfOrder - totalCompareQty }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="ordersChart"
              :chartData="totalOrdersData"
              :options="numberOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
        <!-- store num of views -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_store_views") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalStoreNumOfViews }}
                </h3>
                <template v-if="compareStoreNumOfViews.length">
                  <template
                    v-if="totalStoreNumOfViews > totalCompareStoreNumOfViews"
                  >
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span class="success--text">
                      {{
                        Math.abs(
                          totalStoreNumOfViews - totalCompareStoreNumOfViews
                        )
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      totalStoreNumOfViews < totalCompareStoreNumOfViews
                    "
                  >
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >

                    <span class="error--text">
                      {{
                        Math.abs(
                          totalStoreNumOfViews - totalCompareStoreNumOfViews
                        )
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >

                    <span class="success--text">
                      {{ totalStoreNumOfViews - totalCompareStoreNumOfViews }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="storeViewsChart"
              :chartData="storeViewsData"
              :options="numberOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
        <!-- num of new consumers -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_new_consumer") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalNumOfNewConsumer }}
                </h3>
                <template v-if="compareNumOfNewConsumer.length">
                  <template
                    v-if="totalNumOfNewConsumer > totalCompareNumOfNewConsumer"
                  >
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span class="success--text">
                      {{
                        Math.abs(
                          totalNumOfNewConsumer - totalCompareNumOfNewConsumer
                        )
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      totalNumOfNewConsumer < totalCompareNumOfNewConsumer
                    "
                  >
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >

                    <span class="error--text">
                      {{
                        Math.abs(
                          totalNumOfNewConsumer - totalCompareNumOfNewConsumer
                        )
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >

                    <span class="success--text">
                      {{ totalNumOfNewConsumer - totalCompareNumOfNewConsumer }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="newConsumerChart"
              :chartData="newConsumerData"
              :options="numberOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
        <!-- item num of views -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_item_views") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalItemNumOfViews }}
                </h3>
                <template v-if="compareItemNumOfViews.length">
                  <template
                    v-if="totalItemNumOfViews > totalCompareItemNumOfViews"
                  >
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span class="success--text">
                      {{
                        Math.abs(
                          totalItemNumOfViews - totalCompareItemNumOfViews
                        )
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="totalItemNumOfViews < totalCompareItemNumOfViews"
                  >
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >

                    <span class="error--text">
                      {{
                        Math.abs(
                          totalItemNumOfViews - totalCompareItemNumOfViews
                        )
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >

                    <span class="success--text">
                      {{ totalItemNumOfViews - totalCompareItemNumOfViews }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="storeViewsChart"
              :chartData="itemViewsData"
              :options="numberOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
        <!-- category num of views -->
        <v-col cols="12" sm="6">
          <v-card class="h-100">
            <v-card-title>
              {{ $t("__total_category_views") + ": " }}
              <template v-if="!loading">
                <h3 class="ml-3">
                  {{ totalCategoryNumOfViews }}
                </h3>
                <template v-if="compareCategoryNumOfViews.length">
                  <template
                    v-if="
                      totalCategoryNumOfViews > totalCompareCategoryNumOfViews
                    "
                  >
                    <v-icon class="ml-3" color="success" large
                      >mdi-arrow-up</v-icon
                    >
                    <span class="success--text">
                      {{
                        Math.abs(
                          totalCategoryNumOfViews -
                            totalCompareCategoryNumOfViews
                        )
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      totalCategoryNumOfViews < totalCompareCategoryNumOfViews
                    "
                  >
                    <v-icon class="ml-3" color="error" large
                      >mdi-arrow-down</v-icon
                    >

                    <span class="error--text">
                      {{
                        Math.abs(
                          totalCategoryNumOfViews -
                            totalCompareCategoryNumOfViews
                        )
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <v-icon class="ml-3" color="success" large
                      >mdi-minus</v-icon
                    >

                    <span class="success--text">
                      {{
                        totalCategoryNumOfViews - totalCompareCategoryNumOfViews
                      }}
                    </span>
                  </template>
                </template>
              </template>
            </v-card-title>
            <LineChart
              ref="storeViewsChart"
              :chartData="categoryViewsData"
              :options="numberOptions"
              v-if="!loading"
            />
            <v-progress-circular indeterminate color="primary" v-else />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="analyticsFormShow" max-width="500px" persistent>
      <AnalyticsChartForm
        ref="form"
        :store="store"
        @sendMainTime="sendMainTime"
        @sendCompareTime="sendCompareTime"
        @toLoadMainData="toLoadMainData"
        @toLoadCompareData="toLoadCompareData"
        @cancel="chartFormCancel"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  format,
  formatISO,
  startOfToday,
  endOfToday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  sub,
  startOfDay,
  endOfDay
} from "date-fns";
import LineChart from "@/components/TransactionAnalytics/LineChart.vue";
import AnalyticsChartForm from "@/components/TransactionAnalytics/AnalyticsChartForm.vue";

export default {
  name: "AnalysisChart",
  components: {
    LineChart,
    AnalyticsChartForm
  },
  props: {
    store: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      // trading volume
      quantityOfOrder: "analytics/quantityOfOrder",
      transactionAmount: "analytics/transactionAmount",
      totalQuantityOfOrder: "analytics/totalQuantityOfOrder",
      totalTransactionAmount: "analytics/totalTransactionAmount",
      compareQtyOfOrder: "compareAnalytics/quantityOfOrder",
      compareTransactionAmount: "compareAnalytics/transactionAmount",
      totalCompareQty: "compareAnalytics/totalQuantityOfOrder",
      totalCompareAmount: "compareAnalytics/totalTransactionAmount",
      startTimeAry: "analytics/startTimeAry",
      // views
      storeNumOfViews: "analytics/storeNumOfViews",
      compareStoreNumOfViews: "compareAnalytics/storeNumOfViews",
      totalStoreNumOfViews: "analytics/totalStoreNumOfViews",
      totalCompareStoreNumOfViews: "compareAnalytics/totalStoreNumOfViews",
      // item & category views
      itemNumOfViews: "analytics/itemNumOfViews",
      categoryNumOfViews: "analytics/categoryNumOfViews",
      totalItemNumOfViews: "analytics/totalItemNumOfViews",
      totalCategoryNumOfViews: "analytics/totalCategoryNumOfViews",
      compareItemNumOfViews: "compareAnalytics/itemNumOfViews",
      compareCategoryNumOfViews: "compareAnalytics/categoryNumOfViews",
      totalCompareItemNumOfViews: "compareAnalytics/totalItemNumOfViews",
      totalCompareCategoryNumOfViews:
        "compareAnalytics/totalCategoryNumOfViews",
      // num of new consumer
      numOfNewConsumer: "analytics/numOfNewConsumer",
      compareNumOfNewConsumer: "compareAnalytics/numOfNewConsumer",
      totalNumOfNewConsumer: "analytics/totalNumOfNewConsumer",
      totalCompareNumOfNewConsumer: "compareAnalytics/totalNumOfNewConsumer"
    })
  },
  data() {
    return {
      totalOrdersData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      totalRevenueData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      totalRevenueOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "rgb(0, 0, 0)",
            usePointStyle: true
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 8,
                maxRotation: 0
              },
              scaleLabel: {
                display: true,
                labelString: this.$t("__chart_xAxes"),
                fontSize: 15,
                padding: 6
              }
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 1000,
                callback: function(value) {
                  return "$" + value;
                }
              },
              scaleLabel: {
                display: true,
                labelString: this.$t("__chart_revenue_yAxes"),
                fontSize: 15,
                padding: 6
              }
            }
          ]
        }
      },
      storeViewsData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      itemViewsData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      categoryViewsData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      newConsumerData: {
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:59"
        ]
      },
      numberOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "rgb(0, 0, 0)",
            usePointStyle: true
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 8,
                maxRotation: 0
              },
              scaleLabel: {
                display: true,
                labelString: this.$t("__chart_xAxes"),
                fontSize: 15,
                padding: 6
              }
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 5
              },
              scaleLabel: {
                display: true,
                labelString: this.$t("__chart_orders_yAxes"),
                fontSize: 15,
                padding: 6
              }
            }
          ]
        }
      },
      timeSelect: "today",
      timeOptions: [
        {
          ch: this.$t("__chart_today"),
          en: "today"
        },
        {
          ch: this.$t("__chart_week"),
          en: "week"
        },
        {
          ch: this.$t("__chart_month"),
          en: "month"
        },
        {
          ch: this.$t("__chart_year"),
          en: "year"
        }
      ],
      analyticsFormShow: false,
      mainTimeFormat: format(new Date(), "yyyy/MM/dd"),
      compareTimeFormat: null,
      compare: false
    };
  },
  methods: {
    toLoadMainData({ time, startDate, endDate, itemID, categoryID }) {
      this.timeSelect = time;
      let storeIDs = [];
      storeIDs.push(this.store);
      let startTime, endTime, intervalUnit, intervalStep;
      switch (time) {
        case "today":
          startTime = formatISO(startOfToday());
          endTime = formatISO(endOfToday());
          intervalUnit = "hour";
          intervalStep = 1;
          break;
        case "yesterday":
          startTime = formatISO(startOfDay(sub(new Date(), { days: 1 })));
          endTime = formatISO(endOfDay(sub(new Date(), { days: 1 })));
          intervalUnit = "hour";
          intervalStep = 1;
          break;
        case "thisWeek":
          startTime = formatISO(startOfWeek(new Date()));
          endTime = formatISO(endOfWeek(new Date()));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "lastWeek":
          startTime = formatISO(startOfWeek(sub(new Date(), { weeks: 1 })));
          endTime = formatISO(endOfWeek(sub(new Date(), { weeks: 1 })));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "thisMonth":
          startTime = formatISO(startOfMonth(new Date()));
          endTime = formatISO(endOfMonth(new Date()));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "lastMonth":
          startTime = formatISO(startOfMonth(sub(new Date(), { months: 1 })));
          endTime = formatISO(endOfMonth(sub(new Date(), { months: 1 })));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "thisYear":
          startTime = formatISO(startOfYear(new Date()));
          endTime = formatISO(endOfYear(new Date()));
          intervalUnit = "month";
          intervalStep = 1;
          break;
        case "lastYear":
          startTime = formatISO(startOfYear(sub(new Date(), { years: 1 })));
          endTime = formatISO(endOfYear(sub(new Date(), { years: 1 })));
          intervalUnit = "month";
          intervalStep = 1;
          break;
        case "custom":
          startTime = formatISO(new Date(startDate));
          endTime = formatISO(new Date(endDate));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        default:
          break;
      }
      this.$emit("loadTradingVolume", {
        storeIDs,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      this.$emit("loadStoreViews", {
        storeID: this.store,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      this.$emit("loadNumberOfNewConsumer", {
        storeID: this.store,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      if (itemID) {
        this.$emit("loadItemViews", {
          storeID: this.store,
          itemID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        });
      }
      if (categoryID) {
        this.$emit("loadCategoryViews", {
          storeID: this.store,
          categoryID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        });
      }

      this.analyticsFormShow = false;
    },
    toLoadCompareData({ time, startDate, endDate, itemID, categoryID }) {
      this.compare = true;
      let storeIDs = [];
      storeIDs.push(this.store);
      let startTime, endTime, intervalUnit, intervalStep;
      switch (time) {
        case "yesterday":
          startTime = formatISO(startOfDay(sub(new Date(), { days: 1 })));
          endTime = formatISO(endOfDay(sub(new Date(), { days: 1 })));
          intervalUnit = "hour";
          intervalStep = 1;
          break;
        case "todayLastYear":
          startTime = formatISO(startOfDay(sub(new Date(), { years: 1 })));
          endTime = formatISO(endOfDay(sub(new Date(), { years: 1 })));
          intervalUnit = "hour";
          intervalStep = 1;
          break;
        case "lastWeek":
          startTime = formatISO(startOfWeek(sub(new Date(), { weeks: 1 })));
          endTime = formatISO(endOfWeek(sub(new Date(), { weeks: 1 })));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "thisWeekLastYear":
          startTime = formatISO(startOfWeek(sub(new Date(), { years: 1 })));
          endTime = formatISO(endOfWeek(sub(new Date(), { years: 1 })));
          intervalUnit = "day";
          intervalStep = 1;
          break;

        case "lastMonth":
          startTime = formatISO(startOfMonth(sub(new Date(), { months: 1 })));
          endTime = formatISO(endOfMonth(sub(new Date(), { months: 1 })));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "thisMonthLastYear":
          startTime = formatISO(startOfMonth(new Date()));
          endTime = formatISO(endOfMonth(new Date()));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        case "lastYear":
          startTime = formatISO(startOfYear(sub(new Date(), { years: 1 })));
          endTime = formatISO(endOfYear(sub(new Date(), { years: 1 })));
          intervalUnit = "month";
          intervalStep = 1;
          break;
        case "custom":
          startTime = formatISO(new Date(startDate));
          endTime = formatISO(new Date(endDate));
          intervalUnit = "day";
          intervalStep = 1;
          break;
        default:
          break;
      }
      this.$emit("loadCompareTradingVolume", {
        storeIDs,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      this.$emit("loadCompareStoreViews", {
        storeID: this.store,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      this.$emit("loadCompareNumberOfNewConsumer", {
        storeID: this.store,
        startTime,
        endTime,
        intervalUnit,
        intervalStep
      });
      if (itemID) {
        this.$emit("loadCompareItemViews", {
          storeID: this.store,
          itemID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        });
      }
      if (categoryID) {
        this.$emit("loadCompareCategoryViews", {
          storeID: this.store,
          categoryID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        });
      }
    },
    formatChartData() {
      switch (this.timeSelect) {
        case "today":
          if (this.compare) {
            this.updateChartData(
              "day",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.compareItemNumOfViews,
              this.categoryNumOfViews,
              this.compareCategoryNumOfViews,
              this.compareTransactionAmount,
              this.compareQtyOfOrder,
              this.compareStoreNumOfViews,
              this.compareNumOfNewConsumer
            );
          } else {
            this.updateChartData(
              "day",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.categoryNumOfViews
            );
          }
          break;
        case "yesterday":
          this.updateChartData(
            "day",
            this.transactionAmount,
            this.quantityOfOrder,
            this.storeNumOfViews,
            this.numOfNewConsumer,
            this.itemNumOfViews,
            this.categoryNumOfViews
          );
          break;
        case "thisMonth":
          if (this.compare) {
            this.updateChartData(
              "thisMonth",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.compareItemNumOfViews,
              this.categoryNumOfViews,
              this.compareCategoryNumOfViews,
              this.compareTransactionAmount,
              this.compareQtyOfOrder,
              this.compareStoreNumOfViews,
              this.compareNumOfNewConsumer
            );
          } else {
            this.updateChartData(
              "thisMonth",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.categoryNumOfViews
            );
          }
          break;
        case "lastMonth":
          this.updateChartData(
            "lastMonth",
            this.transactionAmount,
            this.quantityOfOrder,
            this.storeNumOfViews,
            this.numOfNewConsumer,
            this.itemNumOfViews,
            this.categoryNumOfViews
          );
          break;
        case "thisWeek":
          if (this.compare) {
            this.updateChartData(
              "week",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.compareItemNumOfViews,
              this.categoryNumOfViews,
              this.compareCategoryNumOfViews,
              this.compareTransactionAmount,
              this.compareQtyOfOrder,
              this.compareStoreNumOfViews,
              this.compareNumOfNewConsumer
            );
          } else {
            this.updateChartData(
              "week",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.categoryNumOfViews
            );
          }
          break;
        case "lastWeek":
          this.updateChartData(
            "week",
            this.transactionAmount,
            this.quantityOfOrder,
            this.storeNumOfViews,
            this.numOfNewConsumer,
            this.itemNumOfViews,
            this.compareItemNumOfViews,
            this.categoryNumOfViews,
            this.compareCategoryNumOfViews,
            this.compareTransactionAmount,
            this.compareQtyOfOrder,
            this.compareStoreNumOfViews,
            this.compareNumOfNewConsumer
          );
          break;
        case "thisYear":
          if (this.compare) {
            this.updateChartData(
              "year",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.compareItemNumOfViews,
              this.categoryNumOfViews,
              this.compareCategoryNumOfViews,
              this.compareTransactionAmount,
              this.compareQtyOfOrder,
              this.compareStoreNumOfViews,
              this.compareNumOfNewConsumer
            );
          } else {
            this.updateChartData(
              "year",
              this.transactionAmount,
              this.quantityOfOrder,
              this.storeNumOfViews,
              this.numOfNewConsumer,
              this.itemNumOfViews,
              this.categoryNumOfViews
            );
          }
          break;
        case "lastYear":
          this.updateChartData(
            "year",
            this.transactionAmount,
            this.quantityOfOrder,
            this.storeNumOfViews,
            this.numOfNewConsumer,
            this.itemNumOfViews,
            this.categoryNumOfViews
          );
          break;
        case "custom":
          this.updateChartData(
            "custom",
            this.transactionAmount,
            this.quantityOfOrder,
            this.storeNumOfViews,
            this.numOfNewConsumer,
            this.itemNumOfViews,
            this.compareItemNumOfViews,
            this.categoryNumOfViews,
            this.compareCategoryNumOfViews,
            this.compareTransactionAmount,
            this.compareQtyOfOrder,
            this.compareStoreNumOfViews,
            this.compareNumOfNewConsumer
          );
          break;
        default:
          break;
      }
    },
    toSetChart() {
      this.analyticsFormShow = true;
    },
    chartFormCancel() {
      this.analyticsFormShow = false;
    },
    sendMainTime(time) {
      this.mainTimeFormat = time;
    },
    sendCompareTime(time) {
      this.compareTimeFormat = time;
    },
    updateChartData(
      type,
      revenueData,
      ordersData,
      storeViewsData,
      newConsumerData,
      itemViewsData = [],
      compareItemViewsData = [],
      categoryViewsData = [],
      compareCategoryViewsData = [],
      compareRevenue = [],
      compareOrders = [],
      compareStoreViews = [],
      compareNewConsumer = []
    ) {
      let labels = [];
      let endOfMonthDate;
      switch (type) {
        case "day":
          labels = [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:59"
          ];
          break;
        case "week":
          labels = [
            this.$t("__weekday_0"),
            this.$t("__weekday_1"),
            this.$t("__weekday_2"),
            this.$t("__weekday_3"),
            this.$t("__weekday_4"),
            this.$t("__weekday_5"),
            this.$t("__weekday_6")
          ];
          break;
        case "thisMonth":
          endOfMonthDate = endOfMonth(new Date()).getDate();
          labels = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            endOfMonthDate
          ];
          if (endOfMonthDate === 28 || endOfMonthDate === 29) {
            labels.splice(27, 3);
          } else if (endOfMonthDate === 30) {
            labels.pop();
          }
          break;
        case "lastMonth":
          endOfMonthDate = endOfMonth(sub(new Date(), { months: 1 })).getDate();
          labels = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            endOfMonthDate
          ];
          if (endOfMonthDate === 28 || endOfMonthDate === 29) {
            labels.splice(27, 3);
          } else if (endOfMonthDate === 30) {
            labels.pop();
          }
          break;
        case "year":
          labels = [
            this.$t("__month_1"),
            this.$t("__month_2"),
            this.$t("__month_3"),
            this.$t("__month_4"),
            this.$t("__month_5"),
            this.$t("__month_6"),
            this.$t("__month_7"),
            this.$t("__month_8"),
            this.$t("__month_9"),
            this.$t("__month_10"),
            this.$t("__month_11"),
            this.$t("__month_12")
          ];
          break;
        case "custom":
          labels = this.startTimeAry;
          break;
        default:
          break;
      }

      this.totalRevenueData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: revenueData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareRevenue,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
      this.totalOrdersData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: ordersData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareOrders,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
      this.storeViewsData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: storeViewsData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareStoreViews,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
      this.newConsumerData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: newConsumerData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareNewConsumer,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
      this.itemViewsData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: itemViewsData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareItemViewsData,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
      this.categoryViewsData = {
        labels: labels,
        datasets: [
          {
            label: this.$t("__analytics_times_main"),
            data: categoryViewsData,
            borderColor: "#0071bc",
            pointBackgroundColor: "#B5E1FE"
          },
          {
            label: this.$t("__analytics_times_compare"),
            data: compareCategoryViewsData,
            borderColor: "#EA5F02",
            pointBackgroundColor: "orange"
          }
        ]
      };
    }
  },
  watch: {
    transactionAmount: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareTransactionAmount: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    quantityOfOrder: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareQuantityOfOrder: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    storeNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareStoreNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    itemNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareItemNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    categoryNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareCategoryNumOfViews: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    numOfNewConsumer: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    compareNumOfNewConsumer: {
      deep: true,
      handler() {
        this.formatChartData();
      }
    },
    store: {
      immediate: true,
      handler(val) {
        if (val) this.toLoadMainData({ time: "today" });
      }
    },
    timeSelect: {
      handler(val) {
        if (val === "thisYear" || val === "lastYear") {
          this.totalRevenueOptions.scales.yAxes = [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 2000,
                callback: function(value) {
                  return "$" + value;
                }
              },
              scaleLabel: {
                display: true,
                labelString: this.$t("__chart_revenue_yAxes"),
                fontSize: 15,
                padding: 6
              }
            }
          ];
        }
      }
    },
    compareTimeFormat: {
      immediate: true,
      handler(val) {
        if (!val) this.$store.commit("compareAnalytics/CLEAR");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.v-progress-circular {
  display: block;
  width: 100px;
  margin: 30px auto;
}
</style>

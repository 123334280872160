<template>
  <v-container fluid>
    <AnalyticsCard @storeSelect="saveStoreSelect">
      <template v-slot:title>
        {{ $t("__transaction_basic_analysis") }}
      </template>
      <template v-slot:content>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              v-for="data in statisticsData"
              :key="data.icon"
            >
              <v-card>
                <v-card-text class="d-flex flex-column align-center">
                  <v-icon color="primary">
                    {{ data.icon }}
                  </v-icon>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="dataLoading"
                  ></v-progress-circular>
                  <h1 class="py-2" v-if="!dataLoading">{{ data.data }}</h1>
                  <p>{{ data.text }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <AnalyticsChart
            :loading="chartLoading"
            :store="storeSelect"
            @loadTradingVolume="loadTradingVolume"
            @loadCompareTradingVolume="loadCompareTradingVolume"
            @loadStoreViews="loadStoreViews"
            @loadCompareStoreViews="loadCompareStoreViews"
            @loadNumberOfNewConsumer="loadNumberOfNewConsumer"
            @loadCompareNumberOfNewConsumer="loadCompareNumberOfNewConsumer"
            @loadItemViews="loadItemViews"
            @loadCompareItemViews="loadCompareItemViews"
            @loadCategoryViews="loadCategoryViews"
            @loadCompareCategoryViews="loadCompareCategoryViews"
          />
          <!-- <HotItemList /> -->
        </v-card-text>
      </template>
    </AnalyticsCard>
  </v-container>
</template>
<script>
import AnalyticsCard from "@/components/TransactionAnalytics/AnalyticsCard.vue";
import { mapGetters } from "vuex";
import AnalyticsChart from "@/components/TransactionAnalytics/AnalyticsChart.vue";
// import HotItemList from "@/components/TransactionAnalytics/HotItemList.vue";

export default {
  name: "TransactionAnalytics",
  components: {
    AnalyticsChart,
    AnalyticsCard
    // HotItemList
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      statisticsData: [
        {
          icon: "mdi-clipboard-list",
          data: 0,
          text: this.$t("__unshipped_statistics")
        },
        {
          icon: "mdi-cash-multiple",
          data: 0,
          text: this.$t("__unpaid_statistics")
        }
      ],
      dataLoading: false,
      chartLoading: false,
      storeSelect: ""
    };
  },
  methods: {
    loadStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    loadUnshippedOrders() {
      this.dataLoading = true;
      return this.$store
        .dispatch("orders/getOrders", {
          userID: "",
          storeID: this.storeSelect,
          consumerID: "",
          status: "accepted",
          startTime: "0001-01-01T00:00:00Z",
          endTime: "0001-01-01T00:00:00Z"
        })
        .then(res => {
          this.statisticsData[0].data = res.length;
        })
        .catch(err => {
          console.log(err);
        });
    },
    loadUnpaidOrders(storeID = this.storeSelect) {
      this.dataLoading = true;
      return this.$store
        .dispatch("orders/getOrders", {
          userID: "",
          storeID: storeID,
          consumerID: "",
          status: "unpaid",
          startTime: "0001-01-01T00:00:00Z",
          endTime: "0001-01-01T00:00:00Z"
        })
        .then(res => {
          this.statisticsData[1].data = res.length;
          this.dataLoading = false;
        })
        .catch(err => {
          this.dataLoading = false;

          console.log(err);
        });
    },
    loadTradingVolume({
      storeIDs,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("analytics/getTradingVolume", {
          storeIDs,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCompareTradingVolume({
      storeIDs,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("compareAnalytics/getTradingVolume", {
          storeIDs,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadStoreViews({
      storeID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("analytics/getStoreNumberOfViews", {
          storeID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCompareStoreViews({
      storeID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("compareAnalytics/getStoreNumberOfViews", {
          storeID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadItemViews({
      storeID,
      itemID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("analytics/getItemNumberOfViews", {
          storeID,
          itemID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCompareItemViews({
      storeID,
      itemID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("compareAnalytics/getItemNumberOfViews", {
          storeID,
          itemID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCategoryViews({
      storeID,
      categoryID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("analytics/getCategoryNumberOfViews", {
          storeID,
          categoryID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCompareCategoryViews({
      storeID,
      categoryID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("compareAnalytics/getCategoryNumberOfViews", {
          storeID,
          categoryID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadNumberOfNewConsumer({
      storeID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("analytics/getNumberOfNewConsumer", {
          storeID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    loadCompareNumberOfNewConsumer({
      storeID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }) {
      this.chartLoading = true;
      return this.$store
        .dispatch("compareAnalytics/getNumberOfNewConsumer", {
          storeID,
          startTime,
          endTime,
          intervalUnit,
          intervalStep
        })
        .then(() => {
          this.chartLoading = false;
        })
        .catch(err => {
          this.chartLoading = false;
          console.log(err);
        });
    },
    saveStoreSelect(val) {
      this.storeSelect = val;
    }
  },
  watch: {
    storeSelect: {
      handler() {
        this.loadUnshippedOrders().then(() => {
          return this.loadUnpaidOrders();
        });
      }
    }
  }
};
</script>

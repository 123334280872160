<template>
  <v-card>
    <v-card-title>
      {{ $t("__analytics_times_range") }}
    </v-card-title>
    <v-card-text>
      <v-select
        :items="analyticsOptions"
        item-text="ch"
        item-value="en"
        outlined
        dense
        v-model="analyticsSelect"
        @change="compareSelect = null"
      />
      <div class="d-flex mb-5" v-if="analyticsSelect === 'custom'">
        <date-picker
          class="mr-3"
          :outlined="true"
          :dense="true"
          :date.sync="startDate"
          :date-label="$t('__chart_start_date')"
          :locale="$i18n.locale"
        />
        <date-picker
          :outlined="true"
          :dense="true"
          :date.sync="endDate"
          :date-label="$t('__chart_end_date')"
          :max-date="endDate"
          :min-date="startDate"
          :locale="$i18n.locale"
        />
      </div>

      <v-select
        :label="$t('__analytics_times_compare')"
        :items="compareOptions"
        item-text="ch"
        item-value="en"
        outlined
        dense
        v-model="compareSelect"
        :disabled="disabled"
      />
      <div class="d-flex mb-5" v-if="compareSelect === 'custom'">
        <date-picker
          class="mr-3"
          :outlined="true"
          :dense="true"
          :date.sync="compareStartDate"
          :date-label="$t('__chart_start_date')"
          :max-date="compareEndDate"
          :locale="$i18n.locale"
        />
        <date-picker
          :outlined="true"
          :dense="true"
          :date.sync="compareEndDate"
          :date-label="$t('__chart_end_date')"
          :max-date="endDate"
          :min-date="compareStartDate"
          :locale="$i18n.locale"
        />
      </div>
      <div class="d-flex mb-5">
        <v-checkbox
          :label="$t('__analytics_item_check')"
          v-model="itemAnalytics"
          class="mr-3"
          hide-details
        />
        <v-checkbox
          :label="$t('__analytics_category_check')"
          v-model="categoryAnalytics"
          hide-details
        />
      </div>
      <v-combobox
        outlined
        dense
        class="mr-2"
        :label="$t('__analytics_item')"
        v-if="itemAnalytics"
        :items="itemList"
        item-text="name"
        item-value="id"
        v-model="itemSelect"
      />
      <v-combobox
        outlined
        dense
        :label="$t('__analytics_category')"
        v-if="categoryAnalytics"
        :items="categoryList"
        item-text="name"
        item-value="id"
        v-model="categorySelect"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="cancel">{{ $t("__cancel") }}</v-btn>
      <v-btn text color="primary" @click="ok">{{ $t("__confirm") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { format, subDays, startOfWeek, endOfWeek, sub } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "AnalyticsChartForm",
  props: {
    store: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      itemList: "analytics/itemList",
      categoryList: "analytics/categoryList"
    })
  },
  data() {
    return {
      analyticsOptions: [
        {
          ch: this.$t("__chart_today"),
          en: "today"
        },
        {
          ch: this.$t("__chart_yesterday"),
          en: "yesterday"
        },
        {
          ch: this.$t("__chart_week"),
          en: "thisWeek"
        },
        {
          ch: this.$t("__chart_lastweek"),
          en: "lastWeek"
        },
        {
          ch: this.$t("__chart_month"),
          en: "thisMonth"
        },
        {
          ch: this.$t("__chart_lastmonth"),
          en: "lastMonth"
        },
        {
          ch: this.$t("__chart_year"),
          en: "thisYear"
        },
        {
          ch: this.$t("__chart_lastyear"),
          en: "lastYear"
        },
        {
          ch: this.$t("__chart_custom"),
          en: "custom"
        }
      ],
      analyticsSelect: "today",
      compareOptions: [
        {
          ch: this.$t("__chart_compare_none"),
          en: null
        },
        {
          ch: this.$t("__chart_compare_last_period"),
          en: "lastPeriod"
        },
        {
          ch: this.$t("__chart_compare_lastyear_period"),
          en: "lastYearPeriod"
        }
      ],
      startDate: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      compareStartDate: format(sub(new Date(), { months: 2 }), "yyyy-MM-dd"),
      compareEndDate: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
      compareSelect: null,
      mainTimeFormat: "",
      compareTimeFormat: "",
      disabled: false,
      itemAnalytics: false,
      categoryAnalytics: false,
      itemSelect: "",
      categorySelect: ""
    };
  },
  methods: {
    ok() {
      if (!this.compareSelect) {
        this.$emit("toLoadMainData", {
          time: this.analyticsSelect,
          startDate: this.startDate,
          endDate: this.endDate,
          itemID: this.itemAnalytics ? this.itemSelect.id : null,
          categoryID: this.categoryAnalytics ? this.categorySelect.id : null
        });
      } else {
        let compareTime;
        this.$emit("toLoadMainData", {
          time: this.analyticsSelect,
          startDate: this.startDate,
          endDate: this.endDate,
          itemID: this.itemAnalytics ? this.itemSelect.id : null,
          categoryID: this.categoryAnalytics ? this.categorySelect.id : null
        });
        if (
          this.analyticsSelect === "today" &&
          this.compareSelect === "lastPeriod"
        ) {
          compareTime = "yesterday";
        } else if (
          this.analyticsSelect === "today" &&
          this.compareSelect === "lastYearPeriod"
        ) {
          compareTime = "todayLastYear";
        } else if (
          this.analyticsSelect === "thisWeek" &&
          this.compareSelect === "lastPeriod"
        ) {
          compareTime = "lastWeek";
        } else if (
          this.analyticsSelect === "thisWeek" &&
          this.compareSelect === "lastYearPeriod"
        ) {
          compareTime = "thisWeekLastYear";
        } else if (
          this.analyticsSelect === "thisMonth" &&
          this.compareSelect === "lastPeriod"
        ) {
          compareTime = "lastMonth";
        } else if (
          this.analyticsSelect === "thisMonth" &&
          this.compareSelect === "lastYearPeriod"
        ) {
          compareTime = "thisMonthLastYear";
        } else if (
          this.analyticsSelect === "thisYear" &&
          this.compareSelect === "lastPeriod"
        ) {
          compareTime = "lastYear";
        } else if (
          this.analyticsSelect === "thisYear" &&
          this.compareSelect === "lastYearPeriod"
        ) {
          compareTime = "lastYear";
        } else {
          compareTime = "custom";
        }
        // compare custom
        let compareStartDate, compareEndDate;
        if (compareTime === "custom" && this.compareSelect === "lastPeriod") {
          compareStartDate = sub(new Date(this.startDate), { months: 1 });
          compareEndDate = sub(new Date(this.endDate), { months: 1 });
          this.compareTimeFormat = `${format(
            compareStartDate,
            "yyyy/MM/dd"
          )}~${format(compareEndDate, "yyyy/MM/dd")}`;
        } else if (
          compareTime === "custom" &&
          this.compareSelect === "lastYearPeriod"
        ) {
          compareStartDate = sub(new Date(this.startDate), { years: 1 });
          compareEndDate = sub(new Date(this.endDate), { years: 1 });
          this.compareTimeFormat = `${format(
            compareStartDate,
            "yyyy/MM/dd"
          )}~${format(compareEndDate, "yyyy/MM/dd")}`;
        }
        this.$emit("toLoadCompareData", {
          time: compareTime,
          startDate: compareStartDate,
          endDate: compareEndDate,
          itemID: this.itemAnalytics ? this.itemSelect.id : null,
          categoryID: this.categoryAnalytics ? this.categorySelect.id : null
        });
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    loadStoreItemsAndCategories() {
      this.$store.dispatch("analytics/getStoreItems", this.store);
      this.$store.dispatch("analytics/getStoreCategories", this.store);
    }
  },
  watch: {
    analyticsSelect: {
      immediate: true,
      handler(val) {
        let yesterday,
          startOfThisWeek,
          endOfThisWeek,
          startOfLastWeek,
          endOfLastWeek,
          lastMonth,
          lastYear;
        switch (val) {
          case "today":
            this.mainTimeFormat = format(new Date(), "yyyy/MM/dd");
            break;
          case "yesterday":
            yesterday = subDays(new Date(), 1);
            this.mainTimeFormat = format(yesterday, "yyyy/MM/dd");
            break;
          case "thisWeek":
            startOfThisWeek = format(startOfWeek(new Date()), "yyyy/MM/dd");
            endOfThisWeek = format(endOfWeek(new Date()), "yyyy/MM/dd");
            this.mainTimeFormat = `${startOfThisWeek}~${endOfThisWeek}`;
            break;
          case "lastWeek":
            startOfLastWeek = startOfWeek(sub(new Date(), { weeks: 1 }));
            endOfLastWeek = format(endOfWeek(startOfLastWeek), "yyyy/MM/dd");
            this.mainTimeFormat = `${format(
              startOfLastWeek,
              "yyyy/MM/dd"
            )}~${endOfLastWeek}`;
            break;
          case "thisMonth":
            this.mainTimeFormat = format(new Date(), "yyyy/MM");
            break;
          case "lastMonth":
            lastMonth = sub(new Date(), { months: 1 });
            this.mainTimeFormat = format(lastMonth, "yyyy/MM");
            break;
          case "thisYear":
            this.mainTimeFormat = `${new Date().getFullYear()}${this.$t(
              "__analytics_times_year"
            )}`;
            break;
          case "lastYear":
            lastYear = sub(new Date(), { years: 1 });
            this.mainTimeFormat = `${format(lastYear, "yyyy")}${this.$t(
              "__analytics_times_year"
            )}`;
            break;
          case "custom":
            this.mainTimeFormat = `${this.startDate}~${this.endDate}`;
            break;
          default:
            this.startDate = "";
            this.endDate = "";
            break;
        }
        this.$emit("sendMainTime", this.mainTimeFormat);
        this.$emit("sendCompareTime", this.compareTimeFormat);

        if (
          val === "yesterday" ||
          val === "lastWeek" ||
          val === "lastMonth" ||
          val === "lastYear"
        ) {
          this.compareSelect = null;
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    },
    compareSelect: {
      handler(val) {
        this.compareTimeFormat = null;
        // today
        if (this.analyticsSelect === "today" && val === "lastPeriod") {
          this.compareTimeFormat = format(subDays(new Date(), 1), "yyyy/MM/dd");
        } else if (
          this.analyticsSelect === "today" &&
          val === "lastYearPeriod"
        ) {
          this.compareTimeFormat = format(
            sub(new Date(), { years: 1 }),
            "yyyy/MM/dd"
          );
        }

        // this week
        if (this.analyticsSelect === "thisWeek" && val === "lastPeriod") {
          let startOfLastWeek = startOfWeek(sub(new Date(), { weeks: 1 }));
          let endOfLastWeek = format(endOfWeek(startOfLastWeek), "yyyy/MM/dd");
          this.compareTimeFormat = `${format(
            startOfLastWeek,
            "yyyy/MM/dd"
          )}~${endOfLastWeek}`;
        } else if (
          this.analyticsSelect === "thisWeek" &&
          val === "lastYearPeriod"
        ) {
          let startOfLastWeek = startOfWeek(sub(new Date(), { years: 1 }));
          let endOfLastWeek = format(endOfWeek(startOfLastWeek), "yyyy/MM/dd");
          this.compareTimeFormat = `${format(
            startOfLastWeek,
            "yyyy/MM/dd"
          )}~${endOfLastWeek}`;
        }

        // this month

        if (this.analyticsSelect === "thisMonth" && val === "lastPeriod") {
          this.compareTimeFormat = format(
            sub(new Date(), { months: 1 }),
            "yyyy/MM"
          );
        } else if (
          this.analyticsSelect === "thisMonth" &&
          val === "lastYearPeriod"
        ) {
          this.compareTimeFormat = format(
            sub(new Date(), { years: 1 }),
            "yyyy/MM"
          );
        }

        // this year
        if (this.analyticsSelect === "thisYear" && val === "lastPeriod") {
          this.compareTimeFormat = `${format(
            sub(new Date(), { years: 1 }),
            "yyyy"
          )}${this.$t("__analytics_times_year")}`;
        } else if (
          this.analyticsSelect === "thisYear" &&
          val === "lastYearPeriod"
        ) {
          this.compareTimeFormat = `${format(
            sub(new Date(), { years: 1 }),
            "yyyy"
          )}${this.$t("__analytics_times_year")}`;
        }

        // custom
        if (this.analyticsSelect === "custom" && val === "lastPeriod") {
          let compareStartDate = sub(new Date(this.startDate), { months: 1 });
          let compareEndDate = sub(new Date(this.endDate), { months: 1 });
          this.compareTimeFormat = `${format(
            compareStartDate,
            "yyyy/MM/dd"
          )}~${format(compareEndDate, "yyyy/MM/dd")}`;
        } else if (
          this.analyticsSelect === "custom" &&
          val === "lastYearPeriod"
        ) {
          let compareStartDate = sub(new Date(this.startDate), { years: 1 });
          let compareEndDate = sub(new Date(this.endDate), { years: 1 });
          this.compareTimeFormat = `${format(
            compareStartDate,
            "yyyy/MM/dd"
          )}~${format(compareEndDate, "yyyy/MM/dd")}`;
        }
        this.$emit("sendCompareTime", this.compareTimeFormat);
        this.$emit("sendMainTime", this.mainTimeFormat);
      }
    },
    startDate: {
      handler() {
        this.compareSelect = null;
        this.mainTimeFormat = `${this.startDate}~${this.endDate}`;
        this.$emit("sendMainTime", this.mainTimeFormat);
        this.$emit("sendCompareTime", this.compareTimeFormat);
      }
    },
    endDate: {
      handler() {
        this.compareSelect = null;
        this.mainTimeFormat = `${this.startDate}~${this.endDate}`;
        this.$emit("sendMainTime", this.mainTimeFormat);
        this.$emit("sendCompareTime", this.compareTimeFormat);
      }
    },
    store: {
      immediate: true,
      handler() {
        this.loadStoreItemsAndCategories();
      }
    },
    itemAnalytics: {
      handler(val) {
        if (!val) this.itemSelect = "";
      }
    },
    categoryAnalytics: {
      handler(val) {
        if (!val) this.categorySelect = "";
      }
    }
  }
};
</script>

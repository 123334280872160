<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "LineChart",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val) {
          //new data update chart
          this.$data._chart.update();
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>

<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        <slot name="title"></slot>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-title>
      <v-select
        outlined
        dense
        :label="$t('__transaction_analysis_store')"
        :items="storeItems"
        item-text="name"
        item-value="id"
        v-model="storeSelect"
      />
    </v-card-title>
    <slot name="content"></slot>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AnalyticsCard",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      stores: "stores/list"
    }),
    storeItems() {
      let stores = [];
      for (const store of this.stores) {
        let storeName = store.data.name;
        let storeID = store.id;
        stores.push({
          name: storeName,
          id: storeID
        });
      }
      return stores;
    }
  },
  data() {
    return {
      storeSelect: ""
    };
  },
  methods: {
    loadStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    }
  },
  watch: {
    storeItems: {
      handler(val) {
        if (val.length) {
          this.storeSelect = val[0].id;
        }
      }
    },
    storeSelect: {
      handler(val) {
        if (val) this.$emit("storeSelect", val);
      }
    }
  },
  created() {
    this.loadStores();
  }
};
</script>
